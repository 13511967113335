import { navigate } from 'gatsby';
import axios from 'axios';
import { isBrowser } from '../pages';
export const baseUri = () => {
  return 'https://appback.unitedbleachers.net/api/'
}

export const buildQuery = (url, searchInput, filterColumns) => {
  let query = '';
  filterColumns.forEach((filter, index) => {
    if (!filter.includes('.')) {
      query += `&filters[$or][${index}][${filter}][$contains]=${searchInput}`;
    } else {
      let relations = filter.split('.');
      let qryFrmRelation = relations.map((value, index) => {
        return `[${value}]`;
      });
      query += `&filters[$or][${index}]${qryFrmRelation.join('')}[$contains]=${searchInput}`;
    }
  });
  return url + query;
};

export const checkNumber = (element, allowableLength = '', allowMinus = false) => {
  let input = element.value;
  let valid = true;
  let regEx = /[^0-9\.]/gi;
  // This removes any other character but numbers as entered by user
  if (allowMinus) {
    regEx = /[^0-9\.-]/gi;
  }
  if (input.replace(regEx, '')) {
    if (allowableLength) {
      let errorSpan = element.closest('div');
      if (errorSpan.querySelector('span')) {
        errorSpan.querySelector('span').remove();
      }
      var numSplit = allowableLength.split('.');
      let numVal = numSplit[0] ? '9'.repeat(numSplit[0]) : '0';
      let decVal = numSplit[1] ? '.' + '9'.repeat(numSplit[1]) : '.0';
      let maxVal = numVal + decVal;
      if (parseFloat(input) > parseFloat(maxVal)) {
        valid = false; // length 2.2 Input : 100.9
      }

      var inpSplit = input.split('.');
      if (inpSplit[0]) {
        if (allowMinus) {
          if (inpSplit[0].length - 1 > numVal.length) {
            valid = false; // length 2.2 Input : 99.999
          }
        } else if (inpSplit[0].length > numVal.length) {
          valid = false; // length 2.2 Input : 99.999
        }
      }
      if (inpSplit[1]) {
        if (inpSplit[1].length >= decVal.length && parseFloat(inpSplit[1])) {
          valid = false; // length 2.2 Input : 99.999
        }
      }
    }
  }
  element.style.border = '0px solid red';
  let errorSpan = element.closest('div');
  if (errorSpan.querySelector('span')) {
    errorSpan.querySelector('span').remove();
  }
  if (!valid) {
    element.style.border = '1px solid red';
    let span = document.createElement('span');
    span.className = 'error-message';
    span.innerHTML = 'Valid Number(' + allowableLength + ')';
    element.closest('div').append(span);
  }
  return input.replace(regEx, '');
};

export const randomString = () => {
  // used to generate randomstring - customer/supplier mail generate
  return Math.random().toString(36).slice(2);
};

export const numFormatter = (number, isRs = false) => {
  let res = '';
  if (number) {
    if (isRs) {
      res = number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR',
      });
    } else {
      res = number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
      });
    }
  }
  return res;
};

export const isDummyMail = (email, phone) => {
  let isDummyMailPart = email.split('_ubl');
  if (isDummyMailPart[0] == phone) {
    return true;
  } else {
    return false;
  }
};

export const appendErrorMsg = (input, parent = false, msg = 'This field is required', type = 'error') => {
  input.style.border = `1px solid ${type == 'warning' ? '#ffc107' : 'red'}`;
  let span = document.createElement('span');
  span.className = `error-message${type == 'warning' ? ' warning' : ''}`;
  span.innerHTML = msg;
  if (parent) {
    parent.appendChild(span);
  } else {
    input.appendChild(span);
  }
};

export const removeErrorMsg = (element = false) => {
  if (element) {
    element.style.border = 'none';
    let err = element.parentElement.getElementsByClassName('error-message')[0];
    if (err) {
      err.remove();
    }
  } else {
    document.querySelectorAll('.error-message').forEach(function (spanElemnt) {
      spanElemnt.remove();
    });
  }
};

const removeErrorBorder = (input) => {
  input.style.border = 'none';
};

export const requireCheck = (data, records = [], column = '', pickId = 0) => {
  removeErrorMsg();
  let valid = true;
  document.querySelectorAll('.require').forEach(function (input) {
    let inputValue = input.value;
    let parent = input.parentElement;
    let type = 'text';
    let indexName = input.name;

    if (inputValue == undefined) {
      type = 'pulldown';
      indexName = input.id;
      if (indexName.includes('-')) {
        let arr = indexName.split('-');
        let fieldVal = data.data;
        for (var i = 0; i < arr.length; i++) {
          fieldVal = fieldVal[arr[i]] ? fieldVal[arr[i]] : '';
        }
        inputValue = fieldVal;
      } else {
        inputValue = data[indexName] ? data[indexName] : data['data'][indexName];
      }
    }

    if (inputValue == '' || inputValue == undefined) {
      valid = false;
      if (type == 'text') {
        appendErrorMsg(input, parent);
      } else {
        appendErrorMsg(input);
      }
    } else {
      removeErrorBorder(input);
      if (records.length && column) {
        if (indexName == column) {
          let match = [];
          match = records.find((o, i) => {
            if (o['attributes']) {
              if (o['attributes'][column] == inputValue) {
                return true; // stop searching
              }
            } else {
              if (o[column] === inputValue) {
                return true; // stop searching
              }
            }
          });

          if (match && pickId != match.id) {
            valid = false;
            if (type == 'text') {
              appendErrorMsg(input, parent, 'Record already exists');
            } else {
              appendErrorMsg(input, false, 'Record already exists');
            }
          }
        }
      }
    }
  });
  document.querySelectorAll('.json-require').forEach(function (json) {
    resetJson(json);
    let name = json.getAttribute('id');
    let jsonData = data.data[name];
    let match = [];
    match = jsonData.find((o, i) => {
      if (o.checked || (o.text && o.text != '')) {
        return true; // stop searching
      }
    });
    if (!match) {
      valid = false;
      appendErrorMsg(json, false, 'Choose atleast one');
    }
    // po screen -
    if (name === 'graRecepitOty') {
      jsonData.forEach((o, i) => {
        if (o.checked) {
          let qty = o.qty ? parseFloat(o.qty) : 0;
          if (qty <= 0) {
            valid = false;
            appendErrorMsg(json, false, 'Please enter valid qty');
          }
        }
      });
    }
  });
  return valid;
};

export const resetJson = (element, selector = '') => {
  if (selector) {
    element = document.getElementById(selector);
  }
  element.style.border = 'none';
  let err = element.getElementsByClassName('error-message')[0];
  if (err) {
    err.remove();
  }
};

export const resetRequired = (event = false, element = false, force = false) => {
  if (event || element) {
    let targetEl = '';
    if (element) {
      targetEl = element;
    } else {
      targetEl = event.target;
    }
    if (force) {
      targetEl.value = true;
    }
    if (targetEl.value) {
      targetEl.style.border = 'none';
      let err = targetEl.parentElement.getElementsByClassName('error-message')[0];
      if (err) {
        err.remove();
      }
    }
  } else {
    document.querySelectorAll('.error-message').forEach(function (spanElemnt) {
      spanElemnt.remove();
    });
    document.querySelectorAll('.require, .validateGst, .dateValidation, .json-require').forEach(function (input) {
      input.style.border = 'none';
    });
  }
};

export const pullDownReset = (id) => {
  let el = document.getElementById(id);
  el.style.border = 'none';
  let err = el.getElementsByClassName('error-message')[0];
  if (err) {
    err.remove();
  }
};

export const dateFormat = (date) => {
  if (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
};

export const dateTimeFormat = (input) => {
  let date = new Date(input);
  date =
    date.getUTCFullYear() +
    '-' +
    ('00' + (date.getUTCMonth() + 1)).slice(-2) +
    '-' +
    ('00' + date.getUTCDate()).slice(-2) +
    ' ' +
    ('00' + date.getUTCHours()).slice(-2) +
    ':' +
    ('00' + date.getUTCMinutes()).slice(-2) +
    ':' +
    ('00' + date.getUTCSeconds()).slice(-2);
  return date;
};

export const splitByChar = (input) => {
  //let res = input.replace(/,<br ?\/?>/g, "\n")
  return input;
};

export const htmlDateTime = (input) => {
  var now = new Date(input);
  var localDatetime = now.toISOString().substring(0, 16);
  return localDatetime;
};

export const calculateTime = (start, end) => {
  if (start && end) {
    var date1 = new Date(start);
    var date2 = new Date(end);
    var diffMs = date2 - date1;
    var diffDays = Math.floor(diffMs / 86400000);
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if (date2 > date1) {
      return {
        duration:
          'Duration: ' + diffDays
            ? diffDays + ' Day(s), ' + diffHrs + ' Hour(s), ' + diffMins + ' Mins'
            : diffHrs + ' Hours, ' + diffMins + ' Mins',
        valid: diffMs < 0 ? false : true,
      };
    } else {
      return {
        valid: false,
        duration: 'Invalid End Time',
      };
    }
  } else {
    return { duration: '', valid: diffMs < 0 ? false : true };
  }
};

export const allowOnlyHW = (isPost = false) => {
  let valid = true;
  document.querySelectorAll('.restrictHW').forEach(function (input) {
    let inputValue = input.value;
    if (inputValue) {
      let parent = input.parentElement;
      let type = 'text';
      if (inputValue.charAt(0) == 'W' || inputValue.charAt(0) == 'H') {
        if (!isPost) {
          resetRequired(false, input);
        }
      } else {
        valid = false;
        if (type == 'text') {
          appendErrorMsg(input, parent, 'First letter should be W or H');
        } else {
          appendErrorMsg(input, false, 'First letter should be W or H');
        }
      }
    }
  });
  return valid;
};

export const apiError = (msg, selector) => {
  let input = document.getElementsByClassName(selector)[0];
  let parent = input.parentElement;
  appendErrorMsg(input, parent, msg);
};

export const validateGst = () => {
  let valid = true;
  document.querySelectorAll('.validateGst').forEach(function (input) {
    let inputValue = input.value;
    if (inputValue) {
      let parent = input.parentElement;
      var reggst = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([a-zA-Z\d]){1}?$/;
      if (!reggst.test(inputValue) && inputValue != '') {
        valid = false;
        appendErrorMsg(input, parent, 'Invalid Gst Number');
      } else {
        resetRequired(false, input);
      }
    }
  });
  return valid;
};

export const dateGreaterOrEqual = (theDate, toCompare, selector, msg) => {
  let valid = true;
  let d1 = new Date(theDate);
  let d2 = new Date(toCompare);
  if (d1 < d2) {
    let input = document.getElementsByClassName(selector)[0];
    resetRequired(false, input);
    appendErrorMsg(input, input.parentElement, msg);
    valid = false;
  }
  return valid;
};

export const showLoader = () => {
  let loader = document.getElementById('globalLoader');
  loader.classList.add('loaderBlock');
  document.getElementsByTagName('body')[0].style.overflow = 'hidden';
};

export const hideLoader = (isModal = false) => {
  let loader = document.getElementById('globalLoader');
  loader.classList.remove('loaderBlock');
  if (!isModal) {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
};

export const randomKey = (length = 12, chars = 'aeiou') => {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  var result = '';
  for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
  return result;
};

export const measures = () => {
  return [
    { label: 'BOX', value: 1 },
    { label: 'KGS', value: 2 },
    { label: 'LTR', value: 3 },
    { label: 'GLM', value: 4 },
    { label: 'UNIT', value: 5 },
    { label: 'MTS', value: 6 },
    { label: 'NOS', value: 7 },
    { label: 'SET', value: 8 },
    { label: 'TON', value: 9 },
  ];
};

export const PackingSlipCategory = () => {
  return [
    { label: 'Sound', value: 1 },
    { label: 'Seconds', value: 2 },
    { label: 'Short Length', value: 3 },
    { label: 'Fents', value: 4 },
    { label: 'Rags', value: 5 },
    { label: 'Others', value: 6 },
  ];
};

export const idprcoptions = [
  { value: '01', label: '01 - Cotton-Bleached', limit: '1,1500' },
  { value: '02', label: '02 - Cotton- Bld - Cal', limit: '1501,2000' },
  { value: '03', label: '03 - Cotton- Bld - ZZ', limit: '2001,3000' },
  { value: '04', label: '04 -Cotton - Bld - Mer', limit: '3001,5000' },
  { value: '05', label: '05 - Cotton - Dyed', limit: '5001,7000' },
  { value: '06', label: '06 - PC - Bleached', limit: '7001,8000' },
  { value: '07', label: '07 - PC - Dyed', limit: '8001,10000' },
  { value: '08', label: '08 - Uniform', limit: '10001,50000' },
  { value: '09', label: '09 - Hosiery - Bleached', limit: '50001 ,55000' },
  { value: '10', label: '10 - Hosiery  - Dyed', limit: '55001,60000' },
  { value: '11', label: '11 - Others', limit: '60001,99000' },
  { value: '12', label: '12 - Rework', limit: '99001,99950' },
];
export const authCheck = (authData) => {
  showLoader();
  if (!authData) {
    hideLoader();
    navigate('/auth/login');
  }
};

export function refreshPage() {
  window.location.reload();
}

export function checkMax(element, max) {
  if (element.target.value > max) {
    appendErrorMsg(element.target, element.target.parentElement, 'Valid: less than or equal to ' + max);
  } else {
    removeErrorMsg(element.target);
  }
}

export function checkStockEr(container) {
  let err = false;
  var chks = document.querySelectorAll(container);
  chks.forEach((el) => {
    let rs = el.getElementsByClassName('error-message');
    if (rs.length > 0) {
      err = true;
    }
  });
  return err;
}

export function doSelect2() {
  try {
    if ($('.select-box').length) {
      $('.select-box').select2().trigger('change');
      $('.select-box').on('select2:select', function (e) {
        e.currentTarget.dispatchEvent(new Event('change'));
      });
      $('.select-box').removeClass('select-box');
    }
  } catch (err) {
    console.log(err);
  }
}

export function appendScript() {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js';
  if (!document._write) document._write = document.write;
  document.head.append(script);

  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/js/select2.min.js';
  if (!document._write) document._write = document.write;
  document.head.append(script);
}

export function checkIsAdmin(email) {
  return email === 'ublmtp@gmail.com';
}

export const screenHeadingMap = {
  customerSort: 'master/customer-sort',
  customers: 'master/customers',
  groupCodeMaster: 'master/group-code-master',
  operationProcessCodeMaster: 'master/operation-process-code-master',
  machineMaster: 'master/machine-master',
  suppliers: 'master/suppliers',
  sortMaster: 'master/sort-master',
  lotMaster: 'master/lot-master',
  itemMaster: 'master/item-master',
  quotationMaster: 'master/quotation-master',
  graMaster: 'transaction/gra-master',
  graChild: 'transaction/gra-child',
  processInstruction: 'transaction/process-instructions',
  processOrder: 'transaction/process-order',
  jobCard: 'transaction/job-card',
  machineUtilization: 'transaction/machine-utilization',
  storeItemGra: 'transaction/store-item-gra',
  storeConsumption: 'transaction/store-consumption',
  packingSlipEntry: 'transaction/packing-slip-entry',
  graReport: 'reports/gra-report',
  graRecipt: 'reports/gra-recipt',
  graStock: 'reports/gra-stock',
  graTransaction: 'reports/gra-transaction',
  storeConsumptionReport: 'reports/store-consumption-report',
  machineUtilizationReport: 'reports/machine-utilization-report',
  graProcessReport: 'reports/gra-process-report',
  storeItemGraReport: 'reports/store-item-gra',
  storeItemGraInvoice: 'reports/store-item-gra-invoice',
  createAccount: 'admin/create-account',
  resetPassword: 'admin/reset-password',
  screenAccess: 'admin/screen-access',
  dashboard: '/dashboard',
  specGen: 'transaction/spec-gen',
  specGenReport: 'reports/spec-gen-report',
  specGenPieceReport: 'reports/spec-gen-piece-report',
  receiptPOCReport: 'reports/receipt-poc-report',
  realisationReport: 'reports/realisation-report',
  lotWisePackingReport: 'reports/lotwise-packing-report',
  sortWisePackingReport: 'reports/sortwise-packing-report',
  dateWisePackingReport: 'reports/datewise-packing-report',
  closeJobCard: 'transaction/close-jobcard',
  packedStockReport: 'reports/packed-stock-report',
  lotPositionReport: 'reports/lot-position-report',
  storeItemBankReport: 'reports/store-item-bank-report',
  storeItemOtherBankReport: 'reports/store-item-other-bank-report',
  poCreation: 'transaction/po-creation',
  pocPiJobCard: 'reports/poc-pi-jobcard',
  pocAgainstJobCardIssue: 'reports/poc-against-jobcard',
  graPocCustomerWiseReport: 'reports/gra-poc-customerwise',
  machineWiseItemWiseReport: 'reports/machinewise-itemwise',
  machineWiseItemWiseAbstractReport: 'reports/machinewise-itemwise-abstract',
  machineUtilizationSummaryReport: 'reports/machine-utilisation-summary',
  zeroLotNumberQuery: 'reports/zero-lot-number-query',
  machineUtilDailyReport: 'reports/machine-util-daily',
  pocStatusReport: 'reports/poc-status',
};

export const navBarHeadingMap = {
  groupCodeMaster: {
    title: 'Group Code Master',
    link: { to: '/master/group-code-master' },
  },
  operationProcessCodeMaster: {
    title: 'Operation/Process Code Master',
    link: { to: '/master/operation-process-code-master' },
  },
  machineMaster: {
    title: 'Machine Master',
    link: { to: '/master/machine-master' },
  },
  suppliers: {
    title: 'Suppliers',
    link: { to: '/master/suppliers' },
  },
  customers: {
    title: 'Customers',
    link: { to: '/master/customers' },
  },
  sortMaster: {
    title: 'Sort Master',
    link: { to: '/master/sort-master' },
  },
  customerSort: {
    title: 'Customer Sort',
    link: { to: '/master/customer-sort' },
  },
  lotMaster: {
    title: 'Lot Master',
    link: { to: '/master/lot-master' },
  },
  itemMaster: {
    title: 'Item Master',
    link: { to: '/master/item-master' },
  },
  quotationMaster: {
    title: 'Quotation Master',
    link: { to: '/master/quotation-master' },
  },
  graMaster: {
    title: 'GRA Master',
    link: { to: '/transaction/gra-master' },
  },
  graChild: {
    title: 'GRA Child',
    link: { to: '/transaction/gra-child' },
  },
  processInstruction: {
    title: 'Process Instructions',
    link: { to: '/transaction/process-instructions' },
  },
  processOrder: {
    title: 'Process Order Confirmation',
    link: { to: '/transaction/process-order' },
  },
  jobCard: {
    title: 'Job Card',
    link: { to: '/transaction/job-card' },
  },
  machineUtilization: {
    title: 'Machine Utilization',
    link: { to: '/transaction/machine-utilization' },
  },
  storeItemGra: {
    title: 'Store Item GRA',
    link: { to: '/transaction/store-item-gra' },
  },
  storeConsumption: {
    title: 'Store Consumption',
    link: { to: '/transaction/store-consumption' },
  },
  packingSlipEntry: {
    title: 'Packing Slip Entry',
    link: { to: '/transaction/packing-slip-entry' },
  },
  graReport: {
    title: 'GRA Report',
    link: { to: '/reports/gra-report' },
  },
  graRecipt: {
    title: 'GRA Recipts',
    link: { to: '/reports/gra-recipt' },
  },
  graStock: {
    title: 'GRA Stock',
    link: { to: '/reports/gra-stock' },
  },
  graTransaction: {
    title: 'GRA Transaction',
    link: { to: '/reports/gra-transaction' },
  },
  graProcessReport: {
    title: 'GRA Process Report',
    link: { to: '/reports/gra-process-report' },
  },
  storeConsumptionReport: {
    title: 'Store Consumption Report',
    link: { to: '/reports/store-consumption-report' },
  },
  machineUtilizationReport: {
    title: 'Machine Utilization Report',
    link: { to: '/reports/machine-utilization-report' },
  },
  storeItemGraReport: {
    title: 'Store Item GRA Report',
    link: { to: '/reports/store-item-gra' },
  },
  storeItemGraInvoice: {
    title: 'Store Item GRA Invoice Report',
    link: { to: '/reports/store-item-gra-invoice' },
  },
  specGen: {
    title: 'Spec Gen / Dispatch',
    link: { to: '/transaction/spec-gen' },
  },
  specGenReport: {
    title: 'Spec Gen',
    link: { to: '/reports/spec-gen-report' },
  },
  specGenPieceReport: {
    title: 'Spec Gen Piece',
    link: { to: '/reports/spec-gen-piece-report' },
  },
  receiptPOCReport: {
    title: 'Receipt No-POC Link',
    link: { to: '/reports/receipt-poc-report' },
  },
  realisationReport: {
    title: 'Realisation Report',
    link: { to: '/reports/realisation-report' },
  },
  lotWisePackingReport: {
    title: 'Packing Report - LotWise',
    link: { to: '/reports/lotwise-packing-report' },
  },
  sortWisePackingReport: {
    title: 'Packing Report - SortWise',
    link: { to: '/reports/sortwise-packing-report' },
  },
  dateWisePackingReport: {
    title: 'Packing Report - DateWise',
    link: { to: '/reports/datewise-packing-report' },
  },
  closeJobCard: {
    title: 'JobCard - Close',
    link: { to: '/transaction/close-jobcard' },
  },
  packedStockReport: {
    title: 'Packed Stock Report',
    link: { to: '/reports/packed-stock-report' },
  },
  lotPositionReport: {
    title: 'Lot Position Report',
    link: { to: '/reports/lot-position-report' },
  },
  storeItemBankReport: {
    title: 'Store Item Bank Report',
    link: { to: '/reports/store-item-bank-report' },
  },
  storeItemOtherBankReport: {
    title: 'Store Item Other Bank Report',
    link: { to: '/reports/store-item-other-bank-report' },
  },
  poCreation: {
    title: 'PO Creation',
    link: { to: '/transaction/po-creation' },
  },
  pocPiJobCard: {
    title: 'POC-PI-JobCard',
    link: { to: '/reports/poc-pi-jobcard' },
  },
  pocAgainstJobCardIssue: {
    title: 'POC Against JobCard Issue',
    link: { to: '/reports/poc-against-jobcard' },
  },
  graPocCustomerWiseReport: {
    title: 'GRA-POC-CustomerWise',
    link: { to: '/reports/gra-poc-customerwise' },
  },
  machineWiseItemWiseReport: {
    title: 'MachineWise-ItemWise',
    link: { to: '/reports/machinewise-itemwise' },
  },
  machineWiseItemWiseAbstractReport: {
    title: 'MachineWise-ItemWise-Abstract',
    link: { to: '/reports/machinewise-itemwise-abstract' },
  },
  machineUtilizationSummaryReport: {
    title: 'Machine-Utilization-Summary',
    link: { to: '/reports/machine-utilisation-summary' },
  },
  zeroLotNumberQuery: {
    title: 'ZERO Lot Number Query',
    link: { to: '/reports/zero-lot-number-query' },
  },
  machineUtilDailyReport: {
    title: 'Machine Util-Daily',
    link: { to: '/reports/machine-util-daily' },
  },
  pocStatusReport: {
    title: 'POC Status',
    link: { to: '/reports/poc-status' },
  },
};

export const urlHeadingMap = {
  '/master/customer-sort': 'customerSort',
  '/master/customers': 'customers',
  '/master/group-code-master': 'groupCodeMaster',
  '/master/operation-process-code-master': 'operationProcessCodeMaster',
  '/master/machine-master': 'machineMaster',
  '/master/suppliers': 'suppliers',
  '/master/sort-master': 'sortMaster',
  '/master/lot-master': 'lotMaster',
  '/master/item-master': 'itemMaster',
  '/master/quotation-master': 'quotationMaster',
  '/transaction/gra-master': 'graMaster',
  '/transaction/gra-child': 'graChild',
  '/transaction/process-instructions': 'processInstruction',
  '/transaction/process-order': 'processOrder',
  '/transaction/job-card': 'jobCard',
  '/transaction/machine-utilization': 'machineUtilization',
  '/transaction/store-item-gra': 'storeItemGra',
  '/transaction/store-consumption': 'storeConsumption',
  '/transaction/packing-slip-entry': 'packingSlipEntry',
  '/transaction/spec-gen': 'specGen',
  '/transaction/close-jobcard': 'closeJobCard',
  '/transaction/po-creation': 'poCreation',
  '/reports/gra-report': 'graReport',
  '/reports/gra-recipt': 'graRecipt',
  '/reports/gra-stock': 'graStock',
  '/reports/spec-gen-report': 'specGenReport',
  '/reports/spec-gen-piece-report': 'specGenPieceReport',
  '/reports/gra-transaction': 'graTransaction',
  '/reports/store-consumption-report': 'storeConsumptionReport',
  '/reports/machine-utilization-report': 'machineUtilizationReport',
  '/reports/store-item-gra': 'storeItemGraReport',
  '/reports/store-item-gra-invoice': 'storeItemGraInvoice',
  '/reports/gra-process-report': 'graProcessReport',
  '/reports/receipt-poc-report': 'receiptPOCReport',
  '/reports/realisation-report': 'realisationReport',
  '/reports/lotwise-packing-report': 'lotWisePackingReport',
  '/reports/sortwise-packing-report': 'sortWisePackingReport',
  '/reports/datewise-packing-report': 'dateWisePackingReport',
  '/reports/packed-stock-report': 'packedStockReport',
  '/reports/lot-position-report': 'lotPositionReport',
  '/reports/store-item-bank-report': 'storeItemBankReport',
  '/reports/store-item-other-bank-report': 'storeItemOtherBankReport',
  '/reports/poc-pi-jobcard': 'pocPiJobCard',
  '/reports/poc-against-jobcard': 'pocAgainstJobCardIssue',
  '/reports/gra-poc-customerwise': 'graPocCustomerWiseReport',
  '/reports/machinewise-itemwise': 'machineWiseItemWiseReport',
  '/reports/machinewise-itemwise-abstract': 'machineWiseItemWiseAbstractReport',
  '/reports/machine-utilisation-summary': 'machineUtilizationSummaryReport',
  '/reports/zero-lot-number-query': 'zeroLotNumberQuery',
  '/reports/machine-util-daily': 'machineUtilDailyReport',
  '/reports/poc-status': 'pocStatusReport',
  '/admin/create-account': 'createAccount',
  '/admin/reset-password': 'resetPassword',
  '/admin/screen-access': 'screenAccess',
  '/dashboard': 'dashboard',
};

export const initialPageAccess = {
  customerSort: { read: 0, write: 0, edit: 0, delete: 0 },
  customers: { read: 0, write: 0, edit: 0, delete: 0 },
  groupCodeMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  operationProcessCodeMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  machineMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  suppliers: { read: 0, write: 0, edit: 0, delete: 0 },
  sortMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  lotMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  itemMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  quotationMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  graMaster: { read: 0, write: 0, edit: 0, delete: 0 },
  graChild: { read: 0, write: 0, edit: 0, delete: 0 },
  processInstruction: { read: 0, write: 0, edit: 0, delete: 0 },
  processOrder: { read: 0, write: 0, edit: 0, delete: 0 },
  jobCard: { read: 0, write: 0, edit: 0, delete: 0 },
  machineUtilization: { read: 0, write: 0, edit: 0, delete: 0 },
  storeItemGra: { read: 0, write: 0, edit: 0, delete: 0 },
  storeConsumption: { read: 0, write: 0, edit: 0, delete: 0 },
  packingSlipEntry: { read: 0, write: 0, edit: 0, delete: 0 },
  graReport: { read: 0, write: 0, edit: 0, delete: 0 },
  graProcessReport: { read: 0, write: 0, edit: 0, delete: 0 },
  graRecipt: { read: 0, write: 0, edit: 0, delete: 0 },
  graStock: { read: 0, write: 0, edit: 0, delete: 0 },
  graTransaction: { read: 0, write: 0, edit: 0, delete: 0 },
  storeConsumptionReport: { read: 0, write: 0, edit: 0, delete: 0 },
  machineUtilizationReport: { read: 0, write: 0, edit: 0, delete: 0 },
  storeItemGraReport: { read: 0, write: 0, edit: 0, delete: 0 },
  storeItemGraInvoice: { read: 0, write: 0, edit: 0, delete: 0 },
  createAccount: { read: 0, write: 0, edit: 0, delete: 0 },
  resetPassword: { read: 0, write: 0, edit: 0, delete: 0 },
  screenAccess: { read: 0, write: 0, edit: 0, delete: 0 },
  dashboard: { read: 0, write: 0, edit: 0, delete: 0 },
  specGen: { read: 0, write: 0, edit: 0, delete: 0 },
  specGenReport: { read: 0, write: 0, edit: 0, delete: 0 },
  specGenPieceReport: { read: 0, write: 0, edit: 0, delete: 0 },
  receiptPOCReport: { read: 0, write: 0, edit: 0, delete: 0 },
  lotWisePackingReport: { read: 0, write: 0, edit: 0, delete: 0 },
  sortWisePackingReport: { read: 0, write: 0, edit: 0, delete: 0 },
  dateWisePackingReport: { read: 0, write: 0, edit: 0, delete: 0 },
  closeJobCard: { read: 0, write: 0, edit: 0, delete: 0 },
  packedStockReport: { read: 0, write: 0, edit: 0, delete: 0 },
  lotPositionReport: { read: 0, write: 0, edit: 0, delete: 0 },
  storeItemBankReport: { read: 0, write: 0, edit: 0, delete: 0 },
  storeItemOtherBankReport: { read: 0, write: 0, edit: 0, delete: 0 },
  poCreation: { read: 0, write: 0, edit: 0, delete: 0 },
  pocPiJobCard: { read: 0, write: 0, edit: 0, delete: 0 },
  pocAgainstJobCardIssue: { read: 0, write: 0, edit: 0, delete: 0 },
  graPocCustomerWiseReport: { read: 0, write: 0, edit: 0, delete: 0 },
  machineWiseItemWiseReport: { read: 0, write: 0, edit: 0, delete: 0 },
  machineWiseItemWiseAbstractReport: { read: 0, write: 0, edit: 0, delete: 0 },
  machineUtilizationSummaryReport: { read: 0, write: 0, edit: 0, delete: 0 },
  zeroLotNumberQuery: { read: 0, write: 0, edit: 0, delete: 0 },
  machineUtilDailyReport: { read: 0, write: 0, edit: 0, delete: 0 },
  pocStatusReport: { read: 0, write: 0, edit: 0, delete: 0 },
};

export const getPageAccess = async (id, jwt, allData = false) => {
  let res = await axios.get(`${baseUri()}users/${id}?populate[0]=pageAccess`, {
    headers: {
      authorization: 'Bearer ' + jwt,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  let currPathName = window.location.pathname;
  if (currPathName[currPathName.length - 1] === '/') {
    currPathName = currPathName.slice(0, currPathName.length - 1);
  }
  const page = urlHeadingMap[currPathName];
  res = await res.data.pageAccess;
  delete res?.id;
  if (res === null) {
    res = initialPageAccess;
  }
  if (allData) return res;
  return res[page];
};

export const getPageAccessFromState = (state) => {
  if (!isBrowser())
    return {
      read: 0,
      write: 0,
      delete: 0,
    };
  let currPathName = window?.location?.pathname;
  if (currPathName[currPathName.length - 1] === '/') {
    currPathName = currPathName.slice(0, currPathName.length - 1);
  }
  const page = urlHeadingMap[currPathName];
  return state.pageAccess[page];
};
export const checkViewAccess = (state) => {
  if (!isBrowser()) {
    return false;
  }
  let currPathName = window.location.pathname;
  if (currPathName[currPathName.length - 1] === '/') {
    currPathName = currPathName.slice(0, currPathName.length - 1);
  }
  if (
    currPathName === '/auth/login' ||
    currPathName === '/auth/change-password' ||
    currPathName === '/logout' ||
    currPathName === '' ||
    currPathName === '/dashboard'
  ) {
    return true;
  }
  if (currPathName === '/admin/screen-access' && state.authEmail === 'ublmtp@gmail.com') {
    return true;
  }
  const page = urlHeadingMap[currPathName];
  if (state.pageAccess[page]?.read === 1) {
    return true;
  } else {
    return false;
  }
};

export const RestrictedEditUsers = ['ublmtpadmin@gmail.com'];

export const ADMIN = 1;
